<script>
  import { onMount } from "svelte";
  const arr = [
    "genius idea",
    "disruptive company",
    "dream product",
    "subversive podcast",
    "nerdy CSS topic",
    "exciting redesign"
  ];
  let index = 0;
  onMount(() => {
    setInterval(() => {
      if (index < arr.length - 1) {
        index = index + 1;
      } else {
        index = 0;
      }
    }, 1000);
  });
</script>

<style>
  p {
    font-size: 20px;
    font-family: "Spectral";
  }
  h1 {
    margin: auto;
  }
  .large-center {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 15vw;
    margin: auto;
    font-family: Futura;
    line-height: 0.5;
    animation: loadText forwards 1s;
    padding-top: 5vh;
  }
  @keyframes loadText {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes loadTextUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .contact-form {
    position: absolute;
    background: black;
    top: 0;
    right: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr [main] 3fr [end] 1fr;
    grid-template-rows: 20vh [main] auto [footer] 20vh;
    color: white;
    height: 100%;
  }
  .form-body {
    grid-row-start: main;
    grid-column-start: main;
  }
  .intro {
    position: absolute;
    background: black;
    z-index: 1;
    max-width: 60vw;
  }
  a {
    font-weight: 600;
    color: pink;
    text-decoration: underline;
  }
  .paragraph {
    font-size: calc(12px + 0.5vw);
  }
  .footer {
    text-align: right;
    grid-row-start: footer;
    grid-column-start: main;
  }
  .top {
    opacity: 0;
    animation: loadTextUp forwards ease-in-out 0.8s;
    animation-delay: 0.2s;
  }
  .bottom {
    opacity: 0;
    animation: loadTextUp forwards ease-in-out 0.8s;
    animation-delay: 0.4s;
  }
</style>

<div class="contact-form">
  <div class="form-body">
    <p class="paragraph intro">
      I'd love to hear more about your {arr[index]}.
    </p>
    <div class="large-center">
      <h1>hello</h1>
    </div>
  </div>
  <div class="footer">
    <p class="paragraph top">
      You can reach me at
      <a href="mailto:hello@codemotiondesign.com">hello@codemotiondesign.com</a>
    </p>
    <p class="paragraph bottom">Let's collaborate.</p>
  </div>
</div>
