<style>
  .svg {
    height: 100%;
    width: 100%;
  }
  .icon {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 7px;
  }
</style>

<svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.59 151.59">
  <g data-name="Layer 2">
    <g data-name="Hand-drawn Icon Set">
      <text
        transform="matrix(1.05 0 0 1 34.54 89.38)"
        font-size="51"
        font-family="Futura-Bold,Futura"
        font-weight="700">
        <tspan letter-spacing="-.06em">e</tspan>
        <tspan x="29.94" y="0" letter-spacing="-.04em">s</tspan>
        <tspan x="53.85" y="0">c</tspan>
      </text>
      <path class="icon" d="M3.5 3.5h144.59v144.59H3.5z" />
    </g>
  </g>
</svg>
