<style>
  .icon {
    width: 35px;
    height: 35px;
    margin: auto;
  }
</style>

<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 223.78 218.25">
  <g data-name="Layer 2">
    <path
      d="M111.88 0a111.9 111.9 0 00-35.36 218.06c5.6 1 7.64-2.43 7.64-5.4
      0-2.65-.1-9.69-.15-19-31.13
      6.77-37.69-15-37.69-15-5.09-12.93-12.43-16.37-12.43-16.37-10.16-6.94.77-6.8.77-6.8C45.89
      156.26 51.8 167 51.8 167c10 17.1 26.19 12.16 32.56 9.3 1-7.24 3.91-12.17
      7.11-15-24.85-2.83-51-12.43-51-55.3A43.22 43.22 0 0152
      76c-1.15-2.84-5-14.21 1.09-29.62 0 0 9.4-3 30.77 11.47a106.25 106.25 0
      0156 0c21.41-14.45 30.78-11.45 30.78-11.45 6.11 15.41 2.27 26.78 1.12
      29.62a43.16 43.16 0 0111.5 30c0 43-26.16 52.44-51.09 55.21 4 3.45 7.6
      10.28 7.6 20.72 0 15-.14 27-.14 30.69 0 3 2 6.48 7.69 5.38A111.9 111.9 0
      00111.88 0z"
      fill="#181616"
      fill-rule="evenodd"
      data-name="Logo FIXED" />
  </g>
</svg>
