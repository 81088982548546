<style>
  svg {
    width: 4.1875rem;
    height: 1.625rem;
  }
</style>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 481.99 150.15">
  <g data-name="Layer 2">
    <g data-name="Layer 1">
      <path
        class="cls-1"
        d="M78 150c-45.2 0-78-31.2-78-75.6S32.8 0 78 0c38.4 0 68.6 22.8 73
        56.4l-39.2 4.2a33.24 33.24 0 00-32.6-26.2c-22 0-37 16-37 40 .2 24 15.2
        39 36.8 39l104.51-.25v37z" />
      <path
        class="cls-1"
        d="M167.6 6.8h56l16.2 92.8h2l48-92.8h57L321.4 150h-40.8l15.2-86h-2l-45.6
        86h-32.6l-15.2-85.8h-2L183.2 150h-40.8z" />
      <path
        class="cls-1"
        d="M341.6 6.8H400c49.19 0 82 28.6 82 71.6S449.19 150 400 150h-58.4zM400
        118.2c24 0 39.8-15.6 39.8-39.8S424 38.6 400 38.6h-17.6v79.6z" />
    </g>
  </g>
</svg>
