<script>
  import { setContext } from "svelte";
  import { fade } from "svelte/transition";
  import EscButton from "../../public/assets/EscButton.svelte";
  import { BODY_TEXT } from "../utils/constants.js";
  import Gallery from "./Gallery.svelte";
  import KeyboardButton from "./KeyboardButton.svelte";
  import Github from "../../public/assets/icons/Github.svelte";
  import Instagram from "../../public/assets/icons/Instagram.svelte";
  import Behance from "../../public/assets/icons/Behance.svelte";

  export let header = "";
  export let body = "";

  const SOCIAL_ICONS = {
    code: [{ icon: Github, link: "https://github.com/typeoh" }],

    motion: [
      { icon: Instagram, link: "https://www.instagram.com/codemotiondesign" }
    ],
    design: [
      { icon: Behance, link: "https://www.behance.net/codemotiondesign" }
    ]
  };
  const icons = SOCIAL_ICONS[`${header}`];
</script>

<style>
  .overlay {
    position: absolute;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    display: grid;
    grid-template-columns: 1fr [main] 3fr [end] 1fr;
    grid-template-rows: [nav] 20vh [body] auto;
    background: #ffeded;
    overflow: scroll;
  }

  .text-wrapper {
    grid-column-start: main;
    grid-row-start: body;
  }

  @keyframes loadText {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .modal-heading {
    font-size: 15vw;
    line-height: 0;
    text-transform: uppercase;
    animation: loadText forwards 1s;
    margin: 0.8em 0;
  }
  .modal-text {
    opacity: 0;
    font-size: calc(12px + 0.5vw);
    font-family: "Spectral";
    animation: loadText forwards 1s;
    animation-delay: 0.2s;
  }
  .button-container {
    display: flex;
    padding: 5% 0;
  }
</style>

<div class="overlay" transition:fade={{ duration: 300 }}>
  <div class="text-wrapper">
    <p class="modal-heading">{header}</p>
    <p class="modal-text">{body}</p>
    <div class="button-container">
      {#each icons as icon, index}
        <KeyboardButton
          href={icon.link}
          {index}
          class="button"
          Icon={icon.icon} />
      {/each}
    </div>
  </div>
</div>
