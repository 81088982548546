<style>
  svg {
    height: 100%;
    width: 100%;
    margin: auto;
  }
  .icon {
    fill: #fff;
  }
</style>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M0 0h50v50H0z" />
      <path
        class="icon"
        d="M40.34 19.92a1.46 1.46 0 01.25 1.61 13.7 13.7 0 00-1.21 3.81 60.26
        60.26 0 00.45 6.28 8.77 8.77 0 01-2.41 7l-.26.26a8.4 8.4 0 01-11.91
        0l-8.14-8.18a1.47 1.47 0 01-.24-1.82 1.41 1.41 0 012.19-.23l4.18
        4.18a.36.36 0 00.51 0h.05a.36.36 0 000-.5l-7.57-8.09a1.7 1.7 0
        012.46-2.36l6.51 7a.45.45 0 00.64 0 .45.45 0 000-.6l-8-9.33a1.71 1.71 0
        012.5-2.32.94.94 0 00.1.1l7.37
        8.6c.18.21.39.32.58.13l.11-.1c.17-.17.08-.35-.08-.54l-5.82-7.24a1.71
        1.71 0 012.57-2.24l.16.18L31 23l1.28 1.65a3 3 0 001.79 1 1.85 1.85 0
        001.62-1.16 8.3 8.3 0 00.54-2.26 3.17 3.17 0 012.53-2.76h.25a1.72 1.72 0
        011.33.45zM24.88 12.46a2.58 2.58 0 011.69 0l.22.07.11.05a3.38 3.38 0
        01.41.24l.09.06c.05 0 .11.09.16.15a1.53 1.53 0 01.18.18l.06.07c.11.13
        0-.07.05.06a.5.5 0 00.68.18.51.51 0 00.18-.68 3.74 3.74 0
        00-4.15-1.36.5.5 0 00.26 1z" />
      <path
        class="icon"
        d="M23 9.25c3.95-.34 8.63 1 9.95 5.19.19.61 1.15.35 1-.26A8.31 8.31 0
        0030 9.52a12.31 12.31 0 00-7-1.27c-.63.06-.63 1.05 0 1zM13.93 32.8c-.1 0
        .09 0 0 0h-.15s.11 0 0 0h-.15c-.06 0 .11.06 0 0h-.12s-.08-.07 0
        0l-.05-.05s-.06-.1 0 0l-.08-.12v-.07s0-.11-.06-.15 0-.11
        0-.16v-.09c0-.03 0 0 0 0a.88.88 0 000-.17v-.19-.35a.5.5 0
        00-.49-.5.51.51 0 00-.5.5 2.77 2.77 0 00.25 1.55 1.56 1.56 0
        001.61.72.49.49 0 00.35-.61.5.5 0 00-.61-.34zM8.9 28.67a8.87 8.87 0
        00.38 4.6 3.94 3.94 0 002.84 2.37.51.51 0 00.61-.35.49.49 0 00-.35-.61 3
        3 0 01-2.26-2 8.41 8.41 0 01-.23-4 .49.49 0 00-.49-.49.51.51 0 00-.5.49z" />
    </g>
  </g>
</svg>
