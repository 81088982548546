<script>
  import { fade } from "svelte/transition";
  import { reference } from "../main.js";
  export let card = "";

  let currentIndex = "";
  const imageList = [
    { size: "large" },
    { size: "medium" },
    { size: "small" },
    { size: "large" }
  ];

  let imageArray = [];
  imageArray = type => fetchSrc(type);

  function fetchDownloadUrl(ref) {
    return ref.getDownloadURL().then(url => url);
  }

  async function fetchSrc(type) {
    const bucket = reference.child(`gallery/${type}/stills`);
    return await bucket
      .listAll()
      .then(({ items }) =>
        Promise.all(items.map(item => fetchDownloadUrl(item)))
      );
  }

  const smallPath = "../../assets/placeholders/neon_ramen.png";
  const mediumPath = "../../assets/placeholders/walkin_here_bones.png";
  const largePath = "../../assets/placeholders/ripe.png";
  const galleryContainer = document.getElementById("scroll-container");

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  function randomGrid(number) {
    switch (number) {
      case 0:
        return "large";
      case 1:
        return "medium";
      default:
        "full";
    }
  }
</script>

<style>
  .grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 150px;
    grid-auto-flow: row dense;
    grid-area: main;
    font-family: "Spectral";
    margin: 20% 0;
  }

  @keyframes loadImage {
    0% {
      opacity: 0;
      transform: translateY(15%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .image {
    position: relative;
    display: flex;
    flex-direction: column;
    animation: loadImage forwards ease-out 1s;
    animation-delay: calc((var(--animation-order) * 100ms) + 0.5s);
    width: 100%;
    opacity: 0;
  }

  .item {
    height: 100%;
  }
  .image,
  .large {
    background: pink;
    grid-row-end: span 3;
  }
  .medium {
    background: blue;
    grid-row-end: span 2;
  }
  .full {
    background: orange;
    grid-column-end: auto;
  }
</style>

{#await imageArray(card) then src}
  <div class="grid">
    {#each src as image, index}
      <div
        class={`image ${randomGrid(getRandomInt(2))}`}
        style="--animation-order: {index}">
        <img class="item" key={index} src={image} alt={image} />
      </div>
    {/each}
  </div>
{/await}
