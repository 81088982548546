<style>
  aside {
    position: fixed;
    right: 0;
    background: black;
    height: 100%;
    top: 0;
    padding: 3rem;
  }
</style>

<aside id="sidebar" aria-label="Social Links" />
