<script>
  import Logo from "../../public/assets/Logo.svelte";
</script>

<style>
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    pointer-events: none;
  }
</style>

<nav>
  <div class="logo-wrapper">
    <Logo />
  </div>
</nav>
