<style>
  .icon {
    height: 20px;
    width: 20px;
     margin: 0px 0px 0px 5px;
  }
</style>

<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 151.58 184.11">
  <g data-name="Layer 2">
    <g data-name="Hand-drawn Icon Set">
      <path
        d="M148.08 3.5v144.58h-86l-31.24 31.26a4.3 4.3 0 0
        1-7.34-3v-28.26h-20V3.5z"
        fill="#fff"
        stroke="#000"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="7" />
      <path
        d="M109.61 69.77a17.26 17.26 0 0 1-4.61 9.39l-29.18
        29V62.6l8.11-7.87c.22-.25.45-.49.67-.72 11.32-11.71 27.07 1.3 25.01
        15.76zM67.67 54.73l8.12 7.87v45.51L46.6 79.16a17.32 17.32 0 0
        1-4.6-9.39C39.94 55.31 55.68 42.3 67 54z" />
    </g>
  </g>
</svg>
