<script>
  export let href = "";
  export let index = 0;
  export let Icon = {};
</script>

<style>
  @keyframes loadButton {
    0% {
      opacity: 0;
      transform: translateY(20%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .button {
    opacity: 0;
    border: none;
    padding: 0;
    box-shadow: 5px 5px 0px 0px #000;
    transition: box-shadow ease-out 0.1s;
    height: 5vh;
    width: 5vh;
    min-height: 45px;
    min-width: 45px;
    background: none;
    display: flex;
    justify-content: center;
    border: 2px solid black;
    animation: loadButton forwards ease-out 1s;
    animation-delay: calc((var(--animation-order) * 100ms) + 0.5s);
  }
  .button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 0px 0px #000;
  }
  .button:active {
    transform: scale(0.9);
  }
</style>

<a
  class="button"
  key={index}
  role="button"
  {href}
  target="_blank"
  rel="noopener"
  style="--animation-order: {index}">
  <Icon />
</a>
